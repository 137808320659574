var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { color: "primary" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v("Actions")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.newTransactionDialog = true
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "subtitle-2 secondary--text" },
                            [_vm._v("New Transaction")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.mapRemoveDialog = true
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "subtitle-2 secondary--text" },
                            [_vm._v("Map/Remove Resource")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.newResourcePoolDialog = true
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "subtitle-2 secondary--text" },
                            [_vm._v("New Resource Pool")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                persistent: _vm.creatingTransaction,
                "max-width": "500",
              },
              model: {
                value: _vm.newTransactionDialog,
                callback: function ($$v) {
                  _vm.newTransactionDialog = $$v
                },
                expression: "newTransactionDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center secondary--text" },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [_vm._v("add")]),
                        _vm._v(" New transaction "),
                      ],
                      1
                    ),
                  ]),
                  _c("v-spacer"),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "newTransactionForm",
                          model: {
                            value: _vm.validTransaction,
                            callback: function ($$v) {
                              _vm.validTransaction = $$v
                            },
                            expression: "validTransaction",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.availableResourcePools,
                              disabled: _vm.creatingTransaction,
                              "item-text": "long_id",
                              "item-value": "rpid",
                              dense: "",
                              label: "Resource pool",
                              rules: [_vm.rules.required],
                              required: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.selectedResourcePoolForTransaction,
                              callback: function ($$v) {
                                _vm.selectedResourcePoolForTransaction = $$v
                              },
                              expression: "selectedResourcePoolForTransaction",
                            },
                          }),
                          _c("v-select", {
                            attrs: {
                              disabled: _vm.creatingTransaction,
                              items: _vm.resourceTypes,
                              "item-text": "label",
                              "item-value": "value",
                              label: "Resource type",
                              rules: [_vm.rules.required],
                              required: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.transactionResourceType,
                              callback: function ($$v) {
                                _vm.transactionResourceType = $$v
                              },
                              expression: "transactionResourceType",
                            },
                          }),
                          _c("v-select", {
                            attrs: {
                              disabled: _vm.creatingTransaction,
                              "item-text": "type",
                              "item-value": "code",
                              items: _vm.newTransactionTypes,
                              label: "Transaction type",
                              rules: [_vm.rules.required],
                              required: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.transactionType,
                              callback: function ($$v) {
                                _vm.transactionType = $$v
                              },
                              expression: "transactionType",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.creatingTransaction,
                              label: "Amount",
                              rules: _vm.transactionAmountValidationRules,
                              required: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.transactionAmount,
                              callback: function ($$v) {
                                _vm.transactionAmount = $$v
                              },
                              expression: "transactionAmount",
                            },
                          }),
                          _c("v-textarea", {
                            attrs: {
                              disabled: _vm.creatingTransaction,
                              rows: "2",
                              label: "Comment",
                              outlined: "",
                            },
                            model: {
                              value: _vm.transactionComment,
                              callback: function ($$v) {
                                _vm.transactionComment = $$v
                              },
                              expression: "transactionComment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.validTransaction,
                            loading: _vm.creatingTransaction,
                            color: "error",
                            text: "",
                          },
                          on: { click: _vm.createTransaction },
                        },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.creatingTransaction,
                            color: "secondary",
                            text: "",
                          },
                          on: { click: _vm.clearNewTransactionForm },
                        },
                        [_vm._v("Clear")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                persistent: _vm.performingOperation,
                "max-width": "500",
              },
              model: {
                value: _vm.mapRemoveDialog,
                callback: function ($$v) {
                  _vm.mapRemoveDialog = $$v
                },
                expression: "mapRemoveDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center secondary--text" },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [_vm._v("add")]),
                        _vm._v(" Map/Remove Resource "),
                      ],
                      1
                    ),
                  ]),
                  _c("v-spacer"),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.validOperation,
                            callback: function ($$v) {
                              _vm.validOperation = $$v
                            },
                            expression: "validOperation",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.availableResourcePools,
                              disabled: _vm.performingOperation,
                              "item-text": "long_id",
                              "item-value": "rpid",
                              dense: "",
                              label: "Resource pool",
                              outlined: "",
                            },
                            model: {
                              value: _vm.selectedResourcePoolForMapDelete,
                              callback: function ($$v) {
                                _vm.selectedResourcePoolForMapDelete = $$v
                              },
                              expression: "selectedResourcePoolForMapDelete",
                            },
                          }),
                          _c("v-select", {
                            attrs: {
                              items: Object.values(_vm.relationActionTypes),
                              disabled: _vm.performingOperation,
                              label: "Action",
                              rules: [_vm.rules.required],
                              required: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.selectedOperation,
                              callback: function ($$v) {
                                _vm.selectedOperation = $$v
                              },
                              expression: "selectedOperation",
                            },
                          }),
                          _vm.selectedOperation
                            ? _c("v-select", {
                                attrs: {
                                  items: Object.values(_vm.relationActionLevel),
                                  disabled: _vm.performingOperation,
                                  label: "Object type",
                                  rules: [_vm.rules.required],
                                  required: "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.selectedOperationLevel,
                                  callback: function ($$v) {
                                    _vm.selectedOperationLevel = $$v
                                  },
                                  expression: "selectedOperationLevel",
                                },
                              })
                            : _vm._e(),
                          _vm.selectedOperationLevel
                            ? _c("v-text-field", {
                                attrs: {
                                  label:
                                    _vm.selectedOperationLevel ===
                                    _vm.relationActionLevel.ORGANIZATION
                                      ? "Nuvolos organization identifier"
                                      : "Nuvolos space identifier",
                                  type: "number",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.isInteger,
                                  ],
                                  required: "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.nuvolosIdentifier,
                                  callback: function ($$v) {
                                    _vm.nuvolosIdentifier = $$v
                                  },
                                  expression: "nuvolosIdentifier",
                                },
                              })
                            : _vm._e(),
                          _vm.selectedOperationLevel ===
                          _vm.relationActionLevel.SPACE
                            ? _c("v-select", {
                                attrs: {
                                  items: _vm.resourceTypes,
                                  "item-text": "label",
                                  "item-value": "value",
                                  label: "Resource type",
                                  rules: [_vm.rules.required],
                                  required: "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.selectedResourceType,
                                  callback: function ($$v) {
                                    _vm.selectedResourceType = $$v
                                  },
                                  expression: "selectedResourceType",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.validOperation,
                            color: "primary",
                          },
                          on: { click: _vm.performSelectedOperation },
                        },
                        [_vm._v("Confirm Operation")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: { click: _vm.clearResourceMappingForm },
                        },
                        [_vm._v("Clear")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                persistent: _vm.creatingNewResourcePool,
                "max-width": "800",
              },
              model: {
                value: _vm.newResourcePoolDialog,
                callback: function ($$v) {
                  _vm.newResourcePoolDialog = $$v
                },
                expression: "newResourcePoolDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center secondary--text" },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [_vm._v("add")]),
                        _vm._v(" New resource pool "),
                      ],
                      1
                    ),
                  ]),
                  _c("v-spacer"),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "newContractForm",
                          model: {
                            value: _vm.validContract,
                            callback: function ($$v) {
                              _vm.validContract = $$v
                            },
                            expression: "validContract",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.contractTypes,
                              disabled: _vm.creatingNewResourcePool,
                              "item-text": "type",
                              "item-value": "code",
                              dense: "",
                              label: "Contract type",
                              rules: [_vm.rules.required],
                              required: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.newContractType,
                              callback: function ($$v) {
                                _vm.newContractType = $$v
                              },
                              expression: "newContractType",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.creatingNewResourcePool,
                              dense: "",
                              label: "Contract name",
                              rules: [_vm.rules.required],
                              required: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.newContractName,
                              callback: function ($$v) {
                                _vm.newContractName = $$v
                              },
                              expression: "newContractName",
                            },
                          }),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "290px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.creatingNewResourcePool,
                                                label: "Contract duration",
                                                readonly: "",
                                                dense: "",
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.rangeRequired,
                                                  _vm.rules.dateOrder,
                                                ],
                                                required: "",
                                                outlined: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.newContractDateRangeText,
                                                callback: function ($$v) {
                                                  _vm.newContractDateRangeText =
                                                    $$v
                                                },
                                                expression:
                                                  "newContractDateRangeText",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { range: "" },
                                model: {
                                  value: _vm.newContractDurationRange,
                                  callback: function ($$v) {
                                    _vm.newContractDurationRange = $$v
                                  },
                                  expression: "newContractDurationRange",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.creatingNewResourcePool,
                              label: "Initial credit balance",
                              rules: [_vm.rules.required, _vm.rules.isFloat],
                              required: "",
                              dense: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.newContractInitialCredit,
                              callback: function ($$v) {
                                _vm.newContractInitialCredit = $$v
                              },
                              expression: "newContractInitialCredit",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.creatingNewResourcePool,
                              label: "File system storage limit",
                              rules: [_vm.rules.isFloat],
                              suffix: "GB",
                              dense: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.newContractFsStorageLimit,
                              callback: function ($$v) {
                                _vm.newContractFsStorageLimit = $$v
                              },
                              expression: "newContractFsStorageLimit",
                            },
                          }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.creatingNewResourcePool,
                                      label: "Burst capacity",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.isInteger,
                                      ],
                                      required: "",
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.newContractBurstCapacity,
                                      callback: function ($$v) {
                                        _vm.newContractBurstCapacity = $$v
                                      },
                                      expression: "newContractBurstCapacity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.creatingNewResourcePool,
                                      label: "Base capacity",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.isInteger,
                                      ],
                                      required: "",
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.newContractBaseCapacity,
                                      callback: function ($$v) {
                                        _vm.newContractBaseCapacity = $$v
                                      },
                                      expression: "newContractBaseCapacity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.creatingNewResourcePool,
                                      label: "Round-the-clock capacity",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.isInteger,
                                      ],
                                      required: "",
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value:
                                        _vm.newContractRoundTheClockCapacity,
                                      callback: function ($$v) {
                                        _vm.newContractRoundTheClockCapacity =
                                          $$v
                                      },
                                      expression:
                                        "newContractRoundTheClockCapacity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.creatingNewResourcePool,
                                      rules: [
                                        _vm.rules.salesforceNameValidation,
                                      ],
                                      label: "Salesforce name",
                                      dense: "",
                                      outlined: "",
                                    },
                                    on: { input: _vm.validateSalesforce },
                                    model: {
                                      value: _vm.newContractSalesforceName,
                                      callback: function ($$v) {
                                        _vm.newContractSalesforceName = $$v
                                      },
                                      expression: "newContractSalesforceName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: _vm.creatingNewResourcePool,
                                      rules: [
                                        _vm.rules.salesforceUrlValidation,
                                      ],
                                      label: "Salesforce url",
                                      dense: "",
                                      outlined: "",
                                    },
                                    on: { input: _vm.validateSalesforce },
                                    model: {
                                      value: _vm.newContractSalesforceUrl,
                                      callback: function ($$v) {
                                        _vm.newContractSalesforceUrl = $$v
                                      },
                                      expression: "newContractSalesforceUrl",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      disabled: _vm.creatingNewResourcePool,
                                      label: "Add Data support",
                                    },
                                    model: {
                                      value: _vm.newContractHasData,
                                      callback: function ($$v) {
                                        _vm.newContractHasData = $$v
                                      },
                                      expression: "newContractHasData",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      disabled: _vm.creatingNewResourcePool,
                                      label: "Add credit-based sizes support",
                                    },
                                    model: {
                                      value: _vm.newContractHasHPC,
                                      callback: function ($$v) {
                                        _vm.newContractHasHPC = $$v
                                      },
                                      expression: "newContractHasHPC",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      disabled: _vm.creatingNewResourcePool,
                                      label: "Add Video Library support",
                                    },
                                    model: {
                                      value: _vm.newContractHasVimeo,
                                      callback: function ($$v) {
                                        _vm.newContractHasVimeo = $$v
                                      },
                                      expression: "newContractHasVimeo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      disabled: _vm.creatingNewResourcePool,
                                      label: "Create new organization",
                                    },
                                    model: {
                                      value: _vm.newContractAddOrganization,
                                      callback: function ($$v) {
                                        _vm.newContractAddOrganization = $$v
                                      },
                                      expression: "newContractAddOrganization",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.newContractAddOrganization
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "5" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Organization name",
                                          disabled: _vm.creatingNewResourcePool,
                                          rules: [_vm.rules.required],
                                          required: "",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.newContractOrgLongName,
                                          callback: function ($$v) {
                                            _vm.newContractOrgLongName = $$v
                                          },
                                          expression: "newContractOrgLongName",
                                        },
                                      }),
                                      _c("ShortIdInput", {
                                        attrs: {
                                          disabledEdit:
                                            _vm.creatingNewResourcePool,
                                          longId: _vm.newContractOrgLongName,
                                        },
                                        on: {
                                          input: function ($event) {
                                            _vm.newContractOrgShortName =
                                              $event.nextValue
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.newContractAddOrganization
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "5" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          label: "Organization description",
                                          rows: "1",
                                          "auto-grow": "",
                                          disabled: _vm.creatingNewResourcePool,
                                          rules: [_vm.rules.required],
                                          required: "",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.newContractOrgDescription,
                                          callback: function ($$v) {
                                            _vm.newContractOrgDescription = $$v
                                          },
                                          expression:
                                            "newContractOrgDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("v-textarea", {
                            attrs: {
                              disabled: _vm.creatingNewResourcePool,
                              rows: "5",
                              label: "Contract comment",
                              outlined: "",
                            },
                            model: {
                              value: _vm.newContractComment,
                              callback: function ($$v) {
                                _vm.newContractComment = $$v
                              },
                              expression: "newContractComment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.creatingNewResourcePool,
                            color: "primary",
                            text: "",
                          },
                          on: { click: _vm.createNewContract },
                        },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.creatingNewResourcePool,
                            color: "secondary",
                            text: "",
                          },
                          on: { click: _vm.clearContractForm },
                        },
                        [_vm._v("Clear")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "4", sm: "6", cols: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      disabled: _vm.fetchingTransactions,
                      loading: _vm.fetchingTransactions,
                      items: _vm.resourceTypes,
                      "item-text": "label",
                      dense: "",
                      "item-value": "value",
                      label: "Resource type",
                      outlined: "",
                    },
                    on: { change: _vm.fetchTransactions },
                    model: {
                      value: _vm.transactionsListResourceType,
                      callback: function ($$v) {
                        _vm.transactionsListResourceType = $$v
                      },
                      expression: "transactionsListResourceType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "4", sm: "6", cols: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      disabled: _vm.fetchingTransactions,
                      loading: _vm.fetchingTransactions,
                      "item-text": "type",
                      dense: "",
                      "item-value": "code",
                      items: _vm.transactionTypes,
                      label: "Transaction type",
                      outlined: "",
                    },
                    on: { change: _vm.fetchTransactions },
                    model: {
                      value: _vm.transactionsListTransactionType,
                      callback: function ($$v) {
                        _vm.transactionsListTransactionType = $$v
                      },
                      expression: "transactionsListTransactionType",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { md: "4", sm: "6", cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column justify-end" },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.dateRangeOptions,
                        dense: "",
                        label: "Date range",
                        rules: [_vm.rules.required],
                        required: "",
                        outlined: "",
                      },
                      on: { change: _vm.updateDateRange },
                      model: {
                        value: _vm.selectedDateRangeOption,
                        callback: function ($$v) {
                          _vm.selectedDateRangeOption = $$v
                        },
                        expression: "selectedDateRangeOption",
                      },
                    }),
                    _vm.selectedDateRangeOption === "Custom range"
                      ? _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              transition: "scale-transition",
                              "offset-y": "",
                              "min-width": "250px",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              ref: "customDatePicker",
                                              attrs: {
                                                dense: "",
                                                disabled:
                                                  _vm.fetchingTransactions,
                                                label: "Range",
                                                readonly: "",
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.rangeRequired,
                                                  _vm.rules.dateOrder,
                                                ],
                                                required: "",
                                                outlined: "",
                                              },
                                              model: {
                                                value: _vm.dateRangeText,
                                                callback: function ($$v) {
                                                  _vm.dateRangeText = $$v
                                                },
                                                expression: "dateRangeText",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1247056378
                            ),
                          },
                          [
                            _c("v-date-picker", {
                              attrs: {
                                max: new Date().toISOString().substr(0, 10),
                                range: "",
                              },
                              model: {
                                value: _vm.dataDateRange,
                                callback: function ($$v) {
                                  _vm.dataDateRange = $$v
                                },
                                expression: "dataDateRange",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center w-100" },
                  [
                    _vm._v(" Transactions history "),
                    _c("v-spacer"),
                    _c("v-text-field", {
                      attrs: {
                        "append-icon": "mdi-magnify",
                        label: "Search",
                        "single-line": "",
                        "hide-details": "",
                        outlined: "",
                        dense: "",
                      },
                      model: {
                        value: _vm.transactionSearch,
                        callback: function ($$v) {
                          _vm.transactionSearch = $$v
                        },
                        expression: "transactionSearch",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    attrs: {
                      loading: _vm.fetchingTransactions,
                      headers: _vm.transactionsTableHeaders,
                      items: _vm.transactionsHistory,
                      search: _vm.transactionSearch,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.transaction_timestamp`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateToHuman")(
                                      item.transaction_timestamp
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.transaction_type`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getTransactionType(
                                      item.transaction_type
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.resource_type`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getResourceTypeByCode(
                                      item.resource_type
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.transaction_amount`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.roundTransactionAmount(
                                      item.transaction_amount
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }